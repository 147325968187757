/* reset */
html,body,h1,h2,h3,h4,h5,h6,div,dl,dt,dd,ul,ol,li,p,blockquote,pre,hr,figure,table,caption,th,td,form,fieldset,legend,input,button,textarea,menu{margin:0;padding:0;}
header,footer,section,article,aside,nav,hgroup,address,figure,figcaption,menu,details{display:block;}
table{border-collapse:collapse;border-spacing:0;}
caption,th{text-align:left;font-weight:normal;}
html,body,fieldset,img,iframe,abbr{border:0;}
i,cite,em,var,address,dfn{font-style:normal;}
[hidefocus],summary{outline:0;}
li{list-style:none;}
h1,h2,h3,h4,h5,h6,small{font-size:100%;}
sup,sub{font-size:83%;}
pre,code,kbd,samp{font-family:inherit;}
q:before,q:after{content:none;}
textarea{overflow:auto;resize:none;}
label,summary{cursor:default;}
a,button{cursor:pointer;}
h1,h2,h3,h4,h5,h6,em,strong,b{font-weight:bold;}
del,ins,u,s,a,a:hover{text-decoration:none;}
body,textarea,input,button,select,keygen,legend{font:12px/1.6 arial,\5b8b\4f53;color:#666;outline:0;}
body{background:#fff;}
a,a:hover{color:#333;}

/* function */
.f-cb:after,.f-cbli li:after{display:block;clear:both;visibility:hidden;height:0;overflow:hidden;content:".";}
.f-cb,.f-cbli li{zoom:1;}
.f-ib{display:inline-block;*display:inline;*zoom:1;}
.f-dn{display:none;}
.f-db{display:block;}
.f-fl{float:left;}
.f-fr{float:right;}
.f-pr{position:relative;}
.f-prz{position:relative;zoom:1;}
.f-oh{overflow:hidden;}
.f-ff0{font-family:arial,\5b8b\4f53;}
.f-ff1{font-family:"Microsoft YaHei",\5fae\8f6f\96c5\9ed1,arial,\5b8b\4f53;}
.f-fs1{font-size:12px;}
.f-fs2{font-size:14px;}
.f-fwn{font-weight:normal;}
.f-fwb{font-weight:bold;}
.f-tal{text-align:left;}
.f-tac{text-align:center;}
.f-tar{text-align:right;}
.f-taj{text-align:justify;text-justify:inter-ideograph;}
.f-vam,.f-vama *{vertical-align:middle;}
.f-wsn{word-wrap:normal;white-space:nowrap;}
.f-pre{overflow:hidden;text-align:left;white-space:pre-wrap;word-wrap:break-word;word-break:break-all;}
.f-wwb{white-space:normal;word-wrap:break-word;word-break:break-all;}
.f-ti{overflow:hidden;text-indent:-30000px;}
.f-ti2{text-indent:2em;}
.f-lhn{line-height:normal;}
.f-tdu,.f-tdu:hover{text-decoration:underline;}
.f-tdn,.f-tdn:hover{text-decoration:none;}
.f-toe{overflow:hidden;word-wrap:normal;white-space:nowrap;text-overflow:ellipsis;}
.f-cp{cursor:pointer;}
.f-cd{cursor:default;}
.f-ch{cursor:help;}
.f-cm{cursor:move;}
.f-usn{-webkit-user-select:none;-moz-user-select:none;-ms-user-select:none;-o-user-select:none;user-select:none;}
.f-df{display:flex}
.f-fdc{flex-direction:column}
.f-f1{flex:1}
.f-aic{align-items:center}
.f-jcsb{justify-content:space-between}
.f-br0{border-radius:0}
.f-br5{border-radius:5}
.f-br10{border-radius:10}
.f-bb1d{border-bottom:1px solid #ddd}
.f-bl1d{border-left:1px solid #ddd}
.f-cf{color:#fff}
.f-c0{color:#000}
.f-c3{color:#333}
.f-c6{color:#666}
.f-c9{color:#999}
.f-cr{color:red}
.f-cb1,.f-cb1:hover{color:#1890FF}

// 字号
@for $i from 6 through 12 {
  .f-fs#{$i*2} { font-size: #{$i*2}px; }
}
// 边距 填充
@for $i from 1 through 10 {
  .f-mr#{$i*5} { margin-right: #{$i*5}px; }
  .f-mb#{$i*5} { margin-bottom: #{$i*5}px; }
  .f-ml#{$i*5} { margin-left: #{$i*5}px; }
  .f-p#{$i*5} { padding: #{$i*5}px; }
  .f-pt#{$i*5} { padding-top: #{$i*5}px; }
  .f-pr#{$i*5} { padding-right: #{$i*5}px; }
  .f-pb#{$i*5} { padding-bottom: #{$i*5}px; }
  .f-pl#{$i*5} { padding-left: #{$i*5}px; }
}

.u-ti{display:flex;align-items:center;justify-content:space-between;}